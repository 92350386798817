@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import './colors';

.header {
  background-color: $backgroundColor;
  margin-bottom: 30px;

  &__container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }
  &__content {
    padding: 5px 0px;
  }

  &__image {
    width: 100%;
    height: 200px;
    margin-bottom: -5px;
    background: url(../images/banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &--logo {
      height: 95%;
    }
  }
  li,
  a {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: $fontColor;
    text-decoration: none;
  }

  &__title {
    font-size: 21px;
    font-weight: 500;
    text-align: center;
    transition: 0.5s color;
  }

  &__title:hover {
    color: $primaryColor;
  }

  .nav__links {
    display: flex;
    list-style-type: none;
    flex-wrap: wrap;

    li {
      padding: 30px 30px 30px 0;
      a {
        transition: 0.5s color;

        &:hover {
          color: $primaryColor;
        }
      }
    }
  }

  .btn--logout {
    background-color: $primaryColor;
    padding: 9px 25px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: white;
    cursor: pointer;
    border-radius: 5px;

    transition: 0.5s color;
    &:hover {
      color: black;
    }
  }
}
#todo {
  color: red !important;
}
