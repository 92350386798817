.socialLinks {
  text-align: center;
  background-color: white;
  width: 80%;
  margin: 0 auto 25px auto;
  position: relative;
  min-height: 200px;
  &__apps {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 60%;
    margin: 0 auto;
  }

  &__link {
    font-weight: bold;
  }

  &__logo {
    transform: scale(0.5);
    position: absolute;
    top: 0;
    right: 0;
  }
}
