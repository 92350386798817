@import './_header';
@import './_colors';
@import './_footer';
@import './_nomatch';
@import './_login';
@import './_createUser';
@import './_profilePages';
@import './_project';
@import './homePage';
@import './addButton';
@import './_snackbar';
@import './bimScreen';
@import './materialsScreen';
@import './modal';
@import './dashboard';
@import './socialLinks';
@import './media';

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: $bodyColor;
}

.layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.site-content {
  flex: 1;
}
