@import './colors';

.footer {
  z-index: 99;
  bottom: 0;
  width: 100%;
  border-width: 5px;
  padding: 7px 0 7px 0;
  background-color: $secondaryColor;
  color: white;

  &-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin-top: 30px;
  }

  &-banner {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-whiteLine {
      height: 15px;
      width: 100%;
      margin: auto auto;
      background-color: white;
    }
  }
}
