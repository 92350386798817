@import './colors';

.home {
  margin: 0 auto 90px auto;
  width: 80%;

  &__nav {
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: space-between;
    align-items: center;

    &--h1 {
      margin: 0;
    }
  }
  &__project-name {
    color: $primaryColor;
  }
  &__project-table {
    // max-width: 90%;
    margin: 40px auto;
    margin-bottom: 40px;
  }
  &__project-cell {
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}
#emptyTitle {
  text-align: center;
}
