@import './colors';

#signup {
  &__container {
    margin: 50px auto 90px auto !important;
  }

  &__form {
    width: 60%;
    margin: 15px auto 15px auto;
  }

  &__field {
    width: 65%;
    margin: 20px auto 0 auto;
  }

  &__redirect {
    text-align: center;
  }

  &__message {
    text-align: center;
    color: $errorColor;
  }
}

#btn {
  &__signup {
    width: 50%;
    margin: 30px auto 0 auto;
  }
}

#errorMessage {
  color: $errorColor;
}

.signup__title {
  text-align: center;
  margin-bottom: 40px;
  background-color: white;
}

#login__field {
  width: 65%;
  margin: 20px auto 0 auto !important;
}
