@import './colors';

.snackbar {
  z-index: 100;
  display: grid;
  grid-template-columns: 2fr 1fr;
  min-height: 45px;
  width: 300px;
  background-color: $primaryColor;
  color: white;
  justify-items: center;
  align-items: center;
  border-radius: 6px;
  text-align: center;

  /* Animation */
  animation: snackbarAnimationOpen 0.15s linear forwards;

  @keyframes snackbarAnimationOpen {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  &__position {
    position: fixed;
    left: 50%;
    margin-left: -150px;
    bottom: 80px;
  }

  &--close {
    cursor: pointer;
    font-weight: bold;
  }

  &__content {
    font-weight: bold;
    margin: 3px;
  }
}
