@import './colors';

/* Tablet */
@media (max-width: 1050px) {
  .logo,
  .btn--logout {
    margin: 0 !important;
  }
  .header__container {
    flex-direction: column;
  }
  .home {
    width: 90%;
    margin: 0 auto 80px auto;
  }

  .container__toolkit {
    margin: 30px auto 80px auto;
  }
  .invoice {
    width: 100%;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
    &__name {
      grid-column-start: 1;
      grid-column-end: 5;
      border-bottom: solid 2px $lightBorder;
      padding: 10px;
    }
    text-align: center;
  }
  #invoice--icon {
    display: none;
  }

  .nav {
    &__mobile {
      display: none;
    }
  }
  .home__nav {
    width: 100%;
  }
}

@media (max-width: 724px) {
  .nav__links {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    align-items: center;
    justify-items: center;
    margin: 10px 0 10px 0;
  }

  #toolkit {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}

/* Mobile */
@media (max-width: 512px) {
  .btn--logout {
    margin: 30px !important;
  }
  .header__container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .nav {
    &__links {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &__pc {
      display: none !important;
    }
    &__mobile {
      display: block;
    }
  }
  .mobile__menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    position: absolute;
    right: 0;
    left: 0;
    height: 65%;
    width: 100%;
    background-color: $backgroundColor;
    animation: burgerAnimation 1.5s forwards;

    @keyframes burgerAnimation {
      0% {
        top: -50%;
      }
      100% {
        top: 0;
      }
    }

    &--exit {
      position: absolute;
      top: 20px;
      right: 30px;
      color: white;
      cursor: pointer;
    }
  }

  .hamburger {
    border-top: solid 3px $primaryColor;
    border-bottom: solid 3px $primaryColor;
    width: 30px;
    height: 10px;
    cursor: pointer;
  }

  .userInformation {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
    justify-items: center;
    align-items: center;

    &__title {
      grid-column-start: 1;
      grid-column-end: 2;
    }

    &__edit {
      grid-column-start: 1;
      grid-column-end: 2;
    }

    &__label {
      justify-self: center;
    }
  }
  .home__nav {
    flex-direction: column-reverse;
    justify-content: center;

    &--h1 {
      margin-top: 10px !important;
    }
  }
}

/* PC */
@media (min-width: 1001px) {
  .nav {
    &__pc {
      display: block;
    }
    &__mobile {
      display: none;
    }
  }
}
