@import './colors';

.modal {
  &__background {
    opacity: 0.7;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $modalBackground;
    height: 100vh;
    width: 100%;
    z-index: 100;
  }
  &__content {
    z-index: 100;
    position: fixed;
    top: 50%;
    left: 50%;
    height: 150px;
    width: 300px;
    margin-left: -150px;
    margin-top: -75px;
    color: black;
    background-color: white;
    border-radius: 12px;
    padding: 10px;
    text-align: center;

    /* Display */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__X {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
  }
}

.ui.button.modalButton {
  &--Yes {
    margin: 5px 10px;
  }
  &--No {
    margin: 5px 10px;
  }
}
