@import './_colors';

.invoice {
  width: 90%;
  margin: 15px auto;
  padding: 8px 0px;

  display: grid;
  grid-template-columns: 0.25fr repeat(4, 1fr);
  border: 2px solid $lightBorder;

  transition: box-shadow 0.2s;

  #invoice--icon {
    justify-self: end;
    align-self: center;
  }

  &--title {
    font-size: 12px;
    font-weight: 900;
    color: $projectTitleColor;
  }
  &__name {
    display: grid;

    .invoice--name {
      color: $primaryColor;
      font-size: 20px;
      justify-self: center;
      align-self: center;
    }
  }

  &__date {
    display: grid;

    #issue__date {
      justify-self: center;
      align-self: center;
    }

    #date {
      justify-self: center;
      align-self: center;
    }
  }
  &__issueBy {
    display: grid;

    #issue__author {
      justify-self: center;
      align-self: center;
    }

    #author {
      justify-self: center;
      align-self: center;
    }
  }

  &__location {
    display: grid;

    #issue__location {
      justify-self: center;
      align-self: center;
    }

    #location {
      justify-self: center;
      align-self: center;
    }
  }
}

a {
  text-decoration: none;
  font-size: 15px;
  color: black;
}

a:hover {
  color: black;
}

.invoice:hover {
  box-shadow: 1px 1px 1px $boxShadow, -1px -1px 1px $boxShadow;
}

.project {
  &__main {
    max-width: 1000px;
    margin: auto auto 90px auto;
    position: relative;
  }

  &__header {
    text-align: center;
    font-family: inherit;
  }
  &__created {
    text-align: center;
    display: block;
    margin-top: 15px;
  }
  &__tableDiv {
    display: flex;
    justify-content: center;
    margin: 40px 0;
  }

  &__table {
    max-width: 500px;
  }

  &__description {
    display: block;
    text-align: justify;
  }

  &__actionButtons {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.ui.image.projectImage {
  height: 500px !important;
  object-fit: cover;
}

.element {
  &__item {
    margin: 5px 0;
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }
  &__3dlink {
    margin-left: 4px;
  }
  &__3dview {
    display: flex;
    justify-content: center;
    margin-top: 15px !important;
  }
}

.uploadButton--container {
  text-align: center;
  margin: 15px 0;
}
.ui.button.actionButtons {
  margin: 5px 10px;
}

.descriptionImages {
  object-fit: cover;
  width: 100%;

  &--default {
    object-fit: contain;
  }
}
