@import './colors';

#segment_404 {
  width: 80%;
  margin: 100px auto;
  border-color: $primaryColor;
}

#btn--animated {
  background-color: $primaryColor;
  padding: 20px;
}

#error {
  &_code {
    font-size: 100px;
    font-family: 'Teko', sans-serif;
    font-weight: 500;
  }

  &_note {
    font-size: 50px;
    font-family: 'Teko', sans-serif;
    font-weight: 300;
  }

  &_message {
    font-size: 20px;
    font-family: 'Teko', sans-serif;
    font-weight: 300;
  }
}
