@import './colors';

h4 {
  margin: 0;
}

#user__segment {
  width: 80%;
  margin: 60px auto;
  background-color: white;

  &--profile {
    width: 60%;
    margin: 10px auto;
    background-color: white;
  }
}

#user-add {
  font-family: 'Teko', sans-serif;
  font-weight: bold;
}

.userInformation {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, 1fr) 1.5fr;
  align-items: center;
  justify-items: center;
  text-align: center;
  margin-top: 10px;

  &__title {
    margin: 10px;
    font-weight: bolder;
  }

  &__label {
    text-align: bolder;
    margin-bottom: -25px;
  }

  &__edit {
    color: black;
    font-size: 17px;
    margin-top: 15px;
    border-bottom: 2px solid;
    padding: 5px;
    font-weight: bold;
    border-radius: 3px;
  }

  &__edit:hover {
    border-color: $primaryColor;
  }
}
.column {
  text-align: center;
}

.ui.container.profile--container {
  margin-bottom: 90px;
  margin-top: 50px;
  width: 80%;
}
