.materialsContainer {
  margin: 20px auto 90px auto;
  width: 80%;
}

.checkboxContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.checkbox {
  margin-bottom: 8px;
}

.iconsContainer {
  display: flex;
  flex-direction: row;
}

.backButtonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
