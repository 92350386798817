@import './colors';

.dashboard {
  width: 80%;
  margin: 0 auto 75px auto;
}

.ui.button.cardButtons {
  color: black;
  background-color: $primaryColor;
}

.coverImage {
  object-fit: cover;
}
