@import './colors';

.ui.container {
  &.login {
    &__container {
      margin-bottom: 65px !important;
    }
  }
  .btn {
    &__login {
      width: 50%;
      margin: 30px auto 0 auto;
    }
  }
}
.login {
  &__form {
    width: 60%;
    margin: 15px auto 15px auto;
  }
  &__field {
    width: 65%;
    margin: 20px auto 0 auto !important;
  }
  &__redirect {
    text-align: center;
  }

  &__message {
    text-align: center;
    color: $errorColor;
  }
}

#errorMessage {
  color: $errorColor;
}
