$backgroundColor: white;
$projectTitleColor: rgba(0, 0, 0, 0.65);
$fontColor: black;
$walterColor: #1ccfc9;
$walterColorLight: rgba(28, 207, 201, 0.4);
$errorColor: #af3e4d;
$foo: #ff7f11;
$lightBorder: rgba(0, 0, 0, 0.15);
$boxShadow: rgba(28, 207, 201, 0.3);
$information: #2196f3;
$bodyColor: #e6eafd;
$lightTealBackgrond: #eefbfb;
$modalBackground: black;
$primaryColor: #ffc000;
$secondaryColor: #72a7d1;
